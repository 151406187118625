(function ($, window, document) {
    "use strict";
    $(window).on("scroll load", function () {
        if ($(this).scrollTop() >= 30) {
            if ($(".site-header.header_trans-fixed").length) {
                $(".site-header.header_trans-fixed")
                    .not(".fixed-dark")
                    .addClass("pix-header-fixed");
                $(".fixed-dark").addClass("bg-fixed-dark");
                $(".sticky-logo, .header-button-scroll").show();
                $(".main-logo, .header-button-default").hide();
                $(".logo").attr("src", "/public_images/mt-logo.webp");
            }
            if ($(".right-menu.modern").length) {
                $(".right-menu.modern")
                    .closest(".fixed-header")
                    .addClass("fixed-header-scroll");
            }
        } else {
            if ($(".site-header.header_trans-fixed").length) {
                $(".site-header.header_trans-fixed")
                    .not(".fixed-dark")
                    .removeClass("pix-header-fixed");
                $(".fixed-dark").removeClass("bg-fixed-dark");
                $(".sticky-logo, .header-button-scroll").hide();
                $(".main-logo, .header-button-default").show();

                if (
                    window.location.pathname == "/" ||
                    window.location.pathname == ""
                ) {
                    $(".logo").attr("src", "/public_images/mt-logo-white.webp");
                }
            }
        }
    });

    if ($(window).width() >= $(".menu-wrapper").data("top")) {
        $('.site-main-menu li:not(.menu-item-has-children) > a[href^="#"]').on(
            "click",
            function (e) {
                e.preventDefault();
                var elem = $(this).attr("href");
                if ($(elem).length) {
                    $("html,body").animate(
                        {
                            scrollTop:
                                $(elem).offset().top -
                                $(".header_trans-fixed").outerHeight(),
                        },
                        "slow"
                    );
                }
            }
        );
    }

    $(".toggle-menu").on("click", function (e) {
        e.preventDefault();
        var mask = '<div class="mask-overlay">';
        $(mask).hide().appendTo("body").fadeIn("fast");
        $("html")
            .addClass("no-scroll sidebar-open")
            .height(window.innerHeight + "px");
        $(".sidebar-open .site-nav").css("top", "0");
    });

    $(".close-menu, .mask-overlay").on("click", function (e) {
        e.preventDefault();

        $("html").removeClass("no-scroll sidebar-open").height("auto");
        $(".mask-overlay").remove();
    });

    function fixedMobileMenu() {
        var offsetTop;
        var dataTop = $(".menu-wrapper").data("top");

        if ($(window).width() < dataTop) {
            $(".menu-wrapper").css("padding-top", offsetTop + "px");
        } else {
            $(".menu-wrapper").css("padding-top", "0");
        }
    }

    function menuArrows() {
        var mobW = $(".menu-wrapper").attr("data-top");
        if (
            window.outerWidth < mobW ||
            $(".site-header").hasClass("topmenu-arrow")
        ) {
            if (!$(".menu-item-has-children i").length) {
                $("header .menu-item-has-children").append(
                    '<i class="fa fa-angle-down"></i>'
                );
                $("header .menu-item-has-children i").addClass("hide-drop");
            }
            $("header .menu-item-has-children i").on("click", function () {
                if (!$(this).hasClass("animation")) {
                    $(this).parent().toggleClass("is-open");
                    $(this).addClass("animation");
                    $(this)
                        .parent()
                        .siblings()
                        .removeClass("is-open")
                        .find(".fa")
                        .removeClass("hide-drop")
                        .prev(".sub-menu")
                        .slideUp(250);
                    if ($(this).hasClass("hide-drop")) {
                        if ($(this).closest(".sub-menu").length) {
                            $(this)
                                .removeClass("hide-drop")
                                .prev(".sub-menu")
                                .slideToggle(250);
                        } else {
                            $(".menu-item-has-children i")
                                .addClass("hide-drop")
                                .next(".sub-menu")
                                .hide(250);
                            $(this)
                                .removeClass("hide-drop")
                                .prev(".sub-menu")
                                .slideToggle(250);
                        }
                    } else {
                        $(this)
                            .addClass("hide-drop")
                            .prev(".sub-menu")
                            .hide(100)
                            .find(".menu-item-has-children a")
                            .addClass("hide-drop")
                            .prev(".sub-menu")
                            .hide(250);
                    }
                }
                setTimeout(removeClass, 250);

                function removeClass() {
                    $("header .site-main-menu i").removeClass("animation");
                }
            });
        } else {
            $("header .menu-item-has-children i").remove();
        }
    }

    $(window).on("load resize", function () {
        fixedMobileMenu();
        menuArrows();
    });
    window.addEventListener("orientationchange", function () {
        fixedMobileMenu();
        menuArrows();
    });
})(jQuery, window, document);
